<template>
  <v-container fluid>
    <!-- TIME TRACKER -->
    <v-row>
      <v-col sm="12">
        <v-card class="mx-auto">
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline text-left white--text">
                {{ selectedDate | dateView }}
              </v-list-item-title>
            </v-list-item-content>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="newDateSelection"
              persistent
              width="290px"
            >
              <template v-slot:activator="{on}">
                <v-row justify="end">
                  <v-col sm="auto">
                    <v-icon
                      dark
                      title="Previous"
                      @click="addDays(-1)"
                      small
                      class="pr-2"
                      >mdi-chevron-left</v-icon
                    >
                    <v-icon
                      v-on="on"
                      @click="date = selectedDate"
                      dark
                      large
                      title="Choose Day"
                      >event</v-icon
                    >
                    <v-icon
                      dark
                      title="Next"
                      @click="addDays(1)"
                      small
                      class="pl-2"
                      >mdi-chevron-right</v-icon
                    >
                  </v-col>
                </v-row>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"
                  >Cancel</v-btn
                >
                <v-btn text color="primary" @click="$refs.dialog.save(date)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-list-item>
          <v-card-text>
            <v-row justify="end">
              <v-col v-if="!isLocked" sm="auto" class="mt-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      color="primary"
                      @click="getPreviousDayTimesheetEntries"
                      v-on="on"
                      fab
                      depressed
                      small
                    >
                      <v-icon dark>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy Previous Day</span>
                </v-tooltip>
              </v-col>
              <v-col v-if="!isLocked" sm="auto" class="mt-4 mr-4">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      rounded
                      large
                      color="primary"
                      v-on="on"
                      @click="addTime"
                    >
                      <v-icon left dark>mdi-calendar-clock</v-icon>Add Time
                    </v-btn>
                  </template>
                  <span>Add Time</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <div
                v-for="(timesheetEntry, index) in timesheetEntries"
                :key="`tse-${index}`"
              >
                <v-row>
                  <v-col v-if="!isLocked" sm="1">
                    <v-btn
                      @click="editTimesheetEntry(timesheetEntry, index)"
                      icon
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col sm="4">
                    <div style="padding-left: 26px">
                      {{ timesheetEntry.Project.Description }}
                    </div>
                    <div style="font-weight: bold; padding-left: 26px">
                      {{ timesheetEntry.Project.ProjectNumber }} -
                      {{ timesheetEntry.Code.Description }}
                    </div>
                  </v-col>
                  <v-col>
                    <HoursInput
                      :readonly="isLocked"
                      label="hours"
                      v-model="timesheetEntry.Hours"
                      placeholder="0:00"
                    ></HoursInput>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :readonly="isLocked"
                      label="enter note"
                      v-model="timesheetEntry.Note"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!isLocked" sm="1">
                    <v-btn @click="deleteConfirm(index)" icon>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12">
                    <hr color="primary" />
                  </v-col>
                </v-row>
              </div>
              <div
                v-for="(holidayEntry, index) in holidayEntries"
                :key="`he-${index}`"
              >
                <v-row>
                  <v-col sm="4">
                    <div style="padding-left: 26px">Holiday</div>
                    <div style="font-weight: bold; padding-left: 26px">
                      {{ holidayEntry.Holiday.Description }}
                    </div>
                  </v-col>
                  <v-col sm="4">
                    <HoursInput
                      :readonly="isLocked"
                      label="hours"
                      v-model="holidayEntry.Hours"
                      placeholder="0:00"
                    ></HoursInput>
                  </v-col>
                  <v-col v-if="!isLocked" sm="1">
                    <v-btn @click="deleteHolidayConfirm(index)" icon>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12">
                    <hr color="primary" />
                  </v-col>
                </v-row>
              </div>

              <v-row>
                <v-col sm="12" md="3">
                  <v-row>
                    <v-col cols="4" class="mt-4">
                      <v-subheader>Daily Total</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :value="dailyTotal | decimalToTime"
                        :rules="[
                          rules.hoursLessThan24 ||
                            'daily total can not exceed 24 hours',
                        ]"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col sm="12" md="3">
                  <v-row>
                    <v-col cols="4" class="mt-4">
                      <v-subheader>Weekly Total</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :value="weeklyHours.Hours.Total | decimalToTime"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="mt-4"></v-col>
                    <v-col cols="8">
                      <p class="caption font-italic">
                        Expected Weekly Total
                        {{ weeklyHours.Hours.Expected }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col sm="12" md="3" v-if="maxiFlexEmployee">
                  <v-row>
                    <v-col cols="4" class="mt-4">
                      <v-subheader>MaxiFlex Total</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :value="maxiflexHours.Hours.Total | decimalToTime"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="mt-4"></v-col>
                    <v-col cols="8">
                      <p class="caption font-italic">
                        Expected MaxiFlex Total
                        {{ maxiflexHours.Hours.Expected }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col sm="12" md="3">
                  <v-row>
                    <v-col cols="4" class="mt-3">
                      <v-subheader>Monthly Total</v-subheader>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        :value="monthlyHours.Hours.Total | decimalToTime"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="mt-3"></v-col>
                    <v-col cols="8">
                      <p class="caption font-italic">
                        Expected Monthly Total
                        {{ monthlyHours.Hours.Expected }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <v-dialog
              persistent
              v-model="dialog"
              @keydown.esc="dialog = false"
              width="650"
              scrollable
            >
              <v-card class="elevation-3">
                <v-card-title class="primary">
                  <h3 class="hpHeading white--text">{{ modalText }}</h3>
                </v-card-title>
                <v-card-text class="pa-4">
                  <v-form
                    ref="entryForm"
                    v-model="entryValid"
                    :lazy-validation="lazy"
                  >
                    <v-row>
                      <v-col v-if="selectedEntry.ID > 0" sm="12">
                        <v-date-picker
                          landscape
                          v-model="selectedEntry.Date"
                          :min="minDate"
                          :max="maxDate"
                        ></v-date-picker>
                      </v-col>
                      <v-col sm="12">
                        <v-autocomplete
                          v-model="selectedEntry.ProjectID"
                          :items="projectlist"
                          item-text="Description"
                          item-value="ID"
                          label="Project"
                          :filter="projectFilter"
                          :rules="[(v) => !!v || 'project is required']"
                          clearable
                        >
                          <template v-slot:selection="{item}">
                            <span>
                              {{ item.Description }}
                              {{ item.ProjectNumber }}
                            </span>
                          </template>
                          <template v-slot:item="{item}">
                            <span>
                              {{ item.Description }}
                              {{ item.ProjectNumber }}
                            </span>
                          </template>
                        </v-autocomplete>
                        <v-select
                          v-model="selectedEntry.CodeID"
                          :items="availableActivityCodes"
                          item-text="Description"
                          item-value="ID"
                          label="Activity Code"
                          :rules="[(v) => !!v || 'activity code is required']"
                          clearable
                        ></v-select>
                        <HoursInput
                          v-model="selectedEntry.Hours"
                          label="Hours"
                        ></HoursInput>
                        <v-textarea
                          v-model="selectedEntry.Note"
                          label="Enter Note"
                          single-line
                          rows="1"
                          auto-grow
                          clearable
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="updateTimesheetEntry" color="primary"
                    >Accept</v-btn
                  >
                  <v-btn
                    outlined
                    color="primary"
                    @click="cancelEditTimesheetEntry"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
        <!-- SAVE DAY BTN -->
        <v-row class="pa-2" v-if="!isLocked" justify="end">
          <v-col sm="auto">
            <v-btn @click="saveTimeSheet" :disabled="saving" color="primary">
              <v-icon left>mdi-content-save</v-icon>Save Day
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="deleteDialog" scrollable width="350">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Confirm?</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">Are you sure you want to delete this entry?</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn @click="deleteTimesheetEntry" outlined color="primary"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteHolidayDialog" scrollable width="350">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Confirm?</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">Are you sure you want to delete this entry?</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="deleteHolidayDialog = !deleteHolidayDialog"
            >Cancel</v-btn
          >
          <v-btn @click="deleteHoliday" outlined color="primary">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeDateDialog" scrollable width="350">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Continue?</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12">
              "Are you sure you want to leave this page - you have not saved
              your changes?"
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="
              changeDateDialog = false
              routeTo = null
              newDateSelection = selectedDate
            "
            >No</v-btn
          >
          <v-btn
            v-if="newDateSelection != selectedDate"
            @click="changeDate"
            outlined
            color="primary"
            >Yes</v-btn
          >
          <v-btn v-if="routeTo" @click="changeRoute" outlined color="primary"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TimeSheetDetails :timeSheetId="timesheet.ID"></TimeSheetDetails>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import {debounce, isEqual, filter, sortBy, range} from 'lodash'
import {mask} from 'vue-the-mask'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import TimeSheetDetails from '@components/time/timesheetDetails'
import HoursInput from '@components/hoursInput'
import TimeSheetEntry from '@classes/TimeSheetEntry'
import HolidayEntry from '@classes/HolidayEntry'
import TimeSheet from '@classes/TimeSheet'
import LeaveSlip from '@classes/LeaveSlip'
import Rules from '@validation/rules'
import User from '@classes/User'
export default {
  components: {TimeSheetDetails, HoursInput},
  directives: {
    mask,
  },
  data: () => ({
    rules: Rules,
    date: new Date().toISOString().substr(0, 10),
    newDateSelection: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    mask: '##:##',
    dialog: false,
    modalText: '',
    deleteDialog: false,
    deleteHolidayDialog: false,
    changeDateDialog: false,
    valid: true,
    entryValid: true,
    lazy: false,
    menu1: false,
    userProjects: [],
    holiday: null,
    timesheet: new TimeSheet(),
    monthlyHours: {Hours: {}},
    weeklyHours: {Hours: {}},
    maxiflexHours: {Hours: {}},
    holidayEntriesReturned: false,
    holidayEntries: [],
    timesheetEntries: [],
    selectedDate: new Date().toISOString().substr(0, 10),
    selectedEntry: new TimeSheetEntry(),
    selectedEntryIndex: -1,
    originalSelectedEntry: {},
    activityCodes: [],
    entryIndexToDelete: -1,
    holidayEntryIndexToDelete: -1,
    holidayEntryDeleted: false,
    originalTimesheetEntries: [],
    routeTo: null,
    saving: false,
  }),
  filters: {
    decimalToTime(d) {
      var decimalTime = parseFloat(d) * 60 * 60
      var hours = Math.floor(decimalTime / (60 * 60))

      decimalTime = decimalTime - hours * 60 * 60
      var minutes = Math.round(decimalTime / 60)

      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hours + ':' + minutes
    },
  },
  computed: {
    ...authComputed,
    projectlist() {
      let projects = this.userProjects.map((up) => {
        return {...up.Project}
      })
      projects.sort((a, b) => {
        return a.Description > b.Description ? 1 : -1
      })
      return projects
    },
    workWeekType: get('workWeekType/workWeekType'),
    isLocked() {
      var lastStatus = _.takeRight(this.timesheet.TimeSheetStatus)
      //not locked on unsubmitted, awaiting verification & rejected
      if (
        lastStatus.length == 0 ||
        lastStatus[0].StatusID == 1 ||
        lastStatus[0].StatusID == 5
      ) {
        return false
      } else {
        return true
      }
    },

    prevDate() {
      return dayjs(this.selectedDate)
        .subtract(1, 'days')
        .toDate()
        .toISOString()
        .substr(0, 10)
    },
    minDate() {
      if (!!this.timesheet.StartDate) {
        return this.timesheet.StartDate
      } else {
        if (this.currentUser.EmployeePayTypeID == 2) {
          // is hourly
          if (dayjs(this.selectedEntry.Date).date() >= 16) {
            return dayjs([
              dayjs(this.selectedEntry.Date).get('year'),
              dayjs(this.selectedEntry.Date).get('month'),
              16,
            ])
              .toDate()
              .toISOString()
              .substr(0, 10)
          } else {
            return dayjs([
              dayjs(this.selectedEntry.Date).get('year'),
              dayjs(this.selectedEntry.Date)
                .add(-1, 'months')
                .get('month'),
              16,
            ])
              .toDate()
              .toISOString()
              .substr(0, 10)
          }
        } else {
          return dayjs([
            dayjs(this.selectedEntry.Date).get('year'),
            dayjs(this.selectedEntry.Date).get('month'),
          ])
            .toDate()
            .toISOString()
            .substr(0, 10)
        }
      }
    },
    maxDate() {
      if (this.timesheet.EndDate) {
        return this.timesheet.EndDate
      } else {
        if (this.currentUser.EmployeePayTypeID == 2) {
          if (dayjs(this.selectedEntry.Date).date() >= 16) {
            return dayjs([
              dayjs(this.selectedEntry.Date).get('year'),
              dayjs(this.selectedEntry.Date)
                .add(1, 'months')
                .get('month'),
              15,
            ])
              .toDate()
              .toISOString()
              .substr(0, 10)
          } else {
            return dayjs([
              dayjs(this.selectedEntry.Date).get('year'),
              dayjs(this.selectedEntry.Date).get('month'),
              15,
            ])
              .toDate()
              .toISOString()
              .substr(0, 10)
          }
          // is hourly
        } else {
          return dayjs([
            dayjs(this.selectedEntry.Date).get('year'),
            dayjs(this.selectedEntry.Date).get('month'),
          ])
            .endOf('month')
            .startOf('day')
            .toDate()
            .toISOString()
            .substr(0, 10)
        }
      }
    },
    selectedProject() {
      if (this.selectedEntry && this.selectedEntry.ProjectID) {
        return (
          this.userProjects.find(
            (up) => up.ProjectID == this.selectedEntry.ProjectID
          ).Project || {}
        )
      } else {
        return {}
      }
    },
    selectedActivityCode() {
      if (this.selectedEntry && this.selectedEntry.CodeID) {
        return (
          this.activityCodes.find((ac) => ac.ID == this.selectedEntry.CodeID) ||
          {}
        )
      } else {
        return {}
      }
    },
    availableActivityCodes() {
      var activityCodes = this.activityCodes.filter(
        (a) =>
          a.IsActive &&
          a.DivisionID == this.selectedProject.DivisionID &&
          (!a.Prefix || a.Prefix == this.selectedProject.Prefix)
      )
      if (this.selectedEntry.ID > 0) {
        var selectedCode = this.activityCodes.find(
          (c) =>
            c.ID == this.selectedEntry.CodeID &&
            c.DivisionID == this.selectedProject.DivisionID
        )
        if (
          !activityCodes.find((c) => c.ID == this.selectedEntry.CodeID) &&
          selectedCode
        ) {
          activityCodes.push(selectedCode)
        }
      }
      // sort by code or description
      activityCodes.sort((a, b) => {
        return a.Code != b.Code
          ? a.Code - b.Code
          : a.Description > b.Description
          ? 1
          : -1
      })
      return activityCodes
    },
    // projectList() {
    //   var projects = this.userProjects
    //   projects.sort((a, b) => {
    //     return a.Project.Description > b.Project.Description ? 1 : -1
    //   })
    //   return projects
    // },
    // availableActivityCodes_Original() {
    //   var projectIndex = _.findIndex(this.userProjects, (up) => {
    //     return up.Project.ID == this.selectedEntry.ProjectID
    //   })
    //   if (projectIndex !== -1) {
    //     var byDivision = _.filter(this.activityCodes, {
    //       DivisionID: this.userProjects[projectIndex].Project.DivisionID,
    //     })
    //     if (this.selectedEntry.ID > 0 && this.selectedEntry.Code) {
    //       byDivision.push(this.selectedEntry.Code)
    //     }
    //     var sortedList = _.sortBy(byDivision, 'Description')
    //     return sortedList
    //   } else {
    //     return []
    //   }
    // },
    timesheetEntriesChanged() {
      return !(
        JSON.stringify(this.timesheetEntries) ==
        JSON.stringify(this.originalTimesheetEntries)
      )
      // return !_.isEqual(this.originalTimesheetEntries, this.timeSheetEntries)
    },
    dailyTotal() {
      let total = 0
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.holidayEntries.forEach((entry) => {
        if (entry.Hours > 0) {
          total += entry.Hours
        }
      })
      this.timesheetEntries.forEach((entry) => {
        if (entry.Hours > 0) {
          total += entry.Hours
        }
      })
      return total
    },
    weeklyType() {
      return (
        this.workWeekType.find((w) => w.ID == this.timesheet.WorkWeekTypeId)
          .Description || ''
      )
    },
    maxiFlexEmployee() {
      var weekType = this.timesheet.WorkWeekTypeId
      return weekType == 1 || weekType == 3 ? true : false
    },
    maxiFlexFridayThisWeek() {
      let startDate = dayjs('2000-01-01')
      let selectedDay = dayjs(this.selectedDate)
      while (selectedDay.weekday() != 6) {
        selectedDay = selectedDay.add(-1, 'days')
      }
      let difference = selectedDay.diff(startDate, 'week')
      let maxiflexweek = difference % 2 == 0 ? 2 : 1
      if (this.currentUser.WorkWeekTypeID == 1 && maxiflexweek == 1) {
        return true
      } else if (this.currentUser.WorkWeekTypeID == 3 && maxiflexweek == 2) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    selectedDate(nval, oval) {
      if (!!nval) {
        this.bounceDate(this)
      }
    },
    currentUser(nval, oval) {
      if (!!nval) {
        this.bounceDate(this)
        this.getUserProjects()
        this.getActivityCodes()
      }
    },
    holidayEntries(nval, oval) {
      this.resolveHolidays()
    },
    holiday(nval, oval) {
      this.resolveHolidays()
    },
    newDateSelection(nval, oval) {
      if (nval && nval != this.selectedDate) {
        if (!this.timesheetEntriesChanged) {
          this.selectedDate = nval
        } else {
          this.confirmUnsavedChanges()
        }
      }
    },
    holiday(nval, oval) {
      if (!!nval && nval.ID && this.holidayEntries) {
        this.resolveHolidays()
      }
    },
    holidayEntries(nval, oval) {
      if (!!nval && this.holiday && this.holiday.ID) {
        this.resolveHolidays()
      }
    },
    selectedProject(nval, oval) {
      if (
        nval.DivisionID != this.selectedActivityCode.DivisionID ||
        (!!this.selectedActivityCode.Prefix &&
          this.selectedActivityCode.Prefix != nval.Prefix)
      ) {
        //clear selected code
        this.selectedEntry.CodeID = null
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.timesheetEntriesChanged) {
      next()
    } else {
      this.routeTo = next
      this.confirmUnsavedChanges()
      // const answer = window.confirm(
      //   'Do you really want to leave? you have unsaved changes!'
      // )
      // if (answer) {
      //   next()
      // } else {
      //   next(false)
      // }
    }
  },
  created() {
    this.getUserProjects()
    this.getTimeSheetByUserAndDate()
    this.getDataForDate()
    this.getActivityCodes()
    this.loadWorkWeekType()
    this.loadUserLookup()
  },
  methods: {
    async validate() {
      this.$refs.form.validate()
    },
    async validateEntry() {
      this.$refs.entryForm.validate()
    },
    loadWorkWeekType: call('workWeekType/loadWorkWeekType'),
    loadUserLookup: call('user/loadUserLookup'),
    handleError: call('errors/handleError'),
    toastMessage: call('messages/toastMessage'),
    projectFilter(item, queryText, itemText) {
      return (
        item.ProjectNumber.toLocaleLowerCase().indexOf(
          queryText.toLocaleLowerCase()
        ) > -1 ||
        item.Description.toLocaleLowerCase().indexOf(
          queryText.toLocaleLowerCase()
        ) > -1
      )
    },
    timeToDecimal(t) {
      try {
        var arr = t.split(':')
        if (arr.length == 2 && arr[1] != '') {
          return parseFloat(parseInt(arr[0], 10) + parseInt(arr[1], 10) / 60)
        } else {
          return parseInt(arr[0], 10)
        }
      } catch (err) {
        return parseInt(t, 10)
      }
    },
    resolveHolidays() {
      if (this.holiday && this.holiday.ID && this.holidayEntriesReturned) {
        if (this.holidayEntries.length == 0 && !this.holidayEntryDeleted) {
          let holiday = new HolidayEntry({
            TimeSheetID: this.timesheet.ID,
            HolidayID: this.holiday.ID,
            Holiday: this.holiday,
            DateTaken: this.selectedDate,
          })
          this.holidayEntries.push(holiday)
        }
      }
    },
    deleteConfirm(index) {
      this.entryIndexToDelete = index
      this.deleteDialog = true
    },
    deleteHolidayConfirm(index) {
      this.holidayEntryIndexToDelete = index
      this.deleteHolidayDialog = true
    },
    deleteTimesheetEntry() {
      this.timesheetEntries.splice(this.entryIndexToDelete, 1)
      this.entryIndexToDelete = -1
      this.deleteDialog = false
    },
    deleteHoliday() {
      this.holidayEntries.splice(this.holidayEntryIndexToDelete, 1)
      this.holidayEntryIndexToDelete = -1
      this.holidayEntryDeleted = true
      this.deleteHolidayDialog = false
    },

    saveTimeSheet() {
      this.validate().then(() => {
        if (this.valid) {
          this.timesheet.TimeSheetEntry = this.timesheetEntries
          // make sure the timesheetid in the holiday is the correct id
          this.holidayEntries.forEach((holiday) => {
            holiday.TimeSheetID = this.timesheet.ID
          })
          this.timesheet.HolidayEntry = this.holidayEntries
          this.saving = true
          let ts = new TimeSheet(this.timesheet).getSaveData()
          if (ts.ID > 0) {
            //put
            let url = `TimeSheet/${ts.ID}/${this.selectedDate}`
            this.$axios.put(url, ts).then(
              () => {
                this.toastMessage({
                  color: 'success',
                  message: 'timesheet saved',
                })
                this.refreshTimesheetandHolidayEntries()
                eventBus.$emit('refreshTimesheetDetails')
                this.saving = false
              },
              (error) => {
                this.handleError(error)
                console.error(error)
                this.saving = false
              }
            )
          } else {
            //post with date
            let url = 'TimeSheet'
            let data = {
              timesheet: ts,
              date: this.selectedDate,
            }
            this.$axios.post(url, data).then(
              (res) => {
                this.timesheet = res.data
                this.timeSheetId = res.data.ID
                this.toastMessage({
                  color: 'success',
                  message: 'timesheet saved',
                })

                this.refreshTimesheetandHolidayEntries()
                eventBus.$emit('refreshTimesheetDetails')
                this.saving = false
              },
              (error) => {
                this.handleError(error)
                console.error(error)
                this.saving = false
              }
            )
          }
        }
      })
    },
    refreshTimesheetandHolidayEntries() {
      this.getTimesheetEntries()
      this.getHolidayEntries()
    },
    editTimesheetEntry(entry, index) {
      this.modalText = 'Edit Time'
      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation()
      }
      this.selectedEntry = JSON.parse(JSON.stringify(entry))
      this.selectedEntryIndex = index
      this.dialog = true
    },
    cancelEditTimesheetEntry() {
      this.dialog = false
      this.selectedEntry = new TimeSheetEntry()
    },
    updateTimesheetEntry() {
      this.validateEntry().then(() => {
        if (this.entryValid) {
          //set activity code
          this.selectedEntry.Code = this.activityCodes.find(
            (code) => code.ID == this.selectedEntry.CodeID
          )
          //set project
          this.selectedEntry.Project = this.userProjects.find(
            (up) => up.ProjectID == this.selectedEntry.ProjectID
          ).Project
          if (this.selectedEntryIndex >= 0) {
            // -check if it was changed to leave slip entry
            if (this.selectedEntry.Code.DivisionID == 1002) {
              if (this.selectedEntry.LeaveSlipID == null) {
                this.selectedEntry.LeaveSlipID = 0
                this.selectedEntry.LeaveSlip = new LeaveSlip()
              }
              //it's not a leave slip but it used to be.
            } else {
              this.selectedEntry.LeaveSlipID = null
              this.selectedEntry.LeaveSlip = null
            }
            this.$set(
              this.timesheetEntries,
              this.selectedEntryIndex,
              this.selectedEntry
            )
          } else {
            // -check for leave slip
            if (this.selectedEntry.Code.DivisionID == 1002) {
              this.selectedEntry.LeaveSlipID = 0
              this.selectedEntry.LeaveSlip = new LeaveSlip()
            } else {
              this.selectedEntry.LeaveSlipID = null
              this.selectedEntry.LeaveSlip = null
            }
            this.selectedEntry.BillableCategoryID = this.selectedEntry.Code.BillableCategoryID
            this.timesheetEntries.push(this.selectedEntry)
          }
          this.dialog = false
        }
      })
    },
    getActivityCodes() {
      if (this.currentUser) {
        let url = `/ActivityCodes/Division/${this.currentUser.DivisionID}`
        this.$axios.get(url).then(
          (res) => {
            this.activityCodes = res.data
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      }
    },
    addDays(number) {
      console.devlog('addDays', number)
      this.newDateSelection = dayjs(this.selectedDate)
        .add(number, 'days')
        .toDate()
        .toISOString()
        .substr(0, 10)
    },
    confirmUnsavedChanges() {
      this.changeDateDialog = true
    },
    changeDate() {
      this.selectedDate = this.newDateSelection

      this.changeDateDialog = false
    },
    changeRoute() {
      this.routeTo()
    },
    addTime() {
      this.modalText = 'Add Time'
      this.selectedEntry = new TimeSheetEntry({
        TimeSheetID: this.timesheet.ID,
      })
      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation()
      }
      this.selectedEntry.Date = this.selectedDate
      this.selectedEntryIndex = -1
      this.dialog = !this.dialog
    },
    getUserProjects() {
      if (this.currentUser) {
        let url = `/UserProject/User/${this.currentUser.ID}`
        this.$axios.get(url).then(
          (res) => {
            this.userProjects = res.data
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      }
    },
    getTimeSheetByUserAndDate() {
      if (this.currentUser) {
        let url = `/TimeSheet/ByUserAndDate/${this.currentUser.ID}/${this.selectedDate}`
        this.$axios.get(url).then(
          (res) => {
            //will be null if does not exist yet
            if (res.data) {
              this.timesheet = res.data
              this.timeSheetId = res.data.ID
            } else {
              this.timesheet = new TimeSheet({
                UserID: this.currentUser.ID,
                User: {ID: this.currentUser.ID},
                WorkWeekTypeId: this.currentUser.WorkWeekTypeID,
              })
            }
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      }
    },
    getDataForDate() {
      if (this.currentUser) {
        this.getHoliday()
        this.getNewMonthlyTotal()
        this.getNewWeeklyTotal()
        this.getNewMaxiflexTotal()
        this.getTimesheetEntries()
        this.getHolidayEntries()
        this.holidayEntryDeleted = false
      }
    },
    getHoliday() {
      let url = `/Holiday/Date/${this.selectedDate}`
      this.$axios.get(url).then(
        (res) => {
          if (res.data) {
            this.holiday = res.data
          } else {
            if (
              dayjs(this.selectedDate).weekday() == 4 &&
              this.maxiFlexFridayThisWeek
            ) {
              let fridayHolidayDate = dayjs(this.selectedDate)
                .add(1, 'days')
                .toDate()
                .toISOString()
                .substr(0, 10)
              let url = `/Holiday/Date/${fridayHolidayDate}`
              this.$axios.get(url).then((res) => {
                if (res.data) {
                  this.holiday = res.data
                } else {
                  this.holiday = null
                }
              })
            }
            this.holiday = null
          }
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
    getNewMonthlyTotal() {
      let url = `/Timesheet/NewMonthlyTotal/${this.currentUser.ID}/${this.selectedDate}`
      this.$axios.get(url).then(
        (res) => {
          this.monthlyHours = res.data
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
    getNewWeeklyTotal() {
      let url = `/Timesheet/NewWeeklyTotal/${this.currentUser.ID}/${this.selectedDate}`
      this.$axios.get(url).then(
        (res) => {
          this.weeklyHours = res.data
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
    getNewMaxiflexTotal() {
      let url = `/Timesheet/NewMaxiflexTotal/${this.currentUser.ID}/${this.selectedDate}`
      this.$axios.get(url).then(
        (res) => {
          this.maxiflexHours = res.data
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
    getTimesheetEntries() {
      let url = `/TimesheetEntry/ByUserAndDate/${this.currentUser.ID}/${this.selectedDate}`
      this.$axios.get(url).then(
        (res) => {
          this.timesheetEntries = res.data
          this.originalTimesheetEntries = JSON.parse(JSON.stringify(res.data))
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
    getPreviousDayTimesheetEntries() {
      let url = `/TimesheetEntry/ByUserAndDate/${this.currentUser.ID}/${this.prevDate}`
      this.$axios.get(url).then(
        (res) => {
          if (res.data != null) {
            let prevEntries = res.data
            prevEntries.forEach((entry) => {
              entry.Hours = 0
              entry.Date = this.selectedDate
              entry.Note = ''
              entry.ID = 0
              entry.TimeSheetID = this.timesheet.ID
              entry.LeaveSlip = null
              entry.LeaveSlipID = null

              entry = new TimeSheetEntry(entry)
            })
            this.timesheetEntries = prevEntries
          }
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },

    getHolidayEntries() {
      this.holidayEntriesReturned = false
      let url = `/HolidayEntry/ByUserAndDate/${this.currentUser.ID}/${this.selectedDate}`
      this.$axios.get(url).then(
        (res) => {
          this.holidayEntries = res.data
          this.holidayEntriesReturned = true
        },
        (error) => {
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },

    bounceDate: _.debounce((self) => {
      self.getDataForDate()
      self.getTimeSheetByUserAndDate()
    }, 750),
  },
}
//http://doaenterprise.dev.wyo.gov/rest/api/TimeSheets/ByUserIdAndDate/96/2019-08-30

//this if timesheet exists (has id) http://doaenterprise.dev.wyo.gov/rest/api/TimeSheetEntries/ByTimeSheetID/63

//*http://doaenterprise.dev.wyo.gov/rest/api/Holidays/2019-08-30

//http://doaenterprise.dev.wyo.gov/rest/api/UserProjects/ByUserId/96

// *http://doaenterprise.dev.wyo.gov/rest/api/TimeSheets/NewMonthlyTotal/96/2019-08-30

//*http://doaenterprise.dev.wyo.gov/rest/api/TimeSheets/NewWeeklyTotal/96/2019-08-30

//*http://doaenterprise.dev.wyo.gov/rest/api/TimeSheetEntries/ByUserIdAndDate/96/2019-08-30

// *http://doaenterprise.dev.wyo.gov/rest/api/HolidayEntries/ByUserIdAndDate/96/2019-08-30

//SAVE http://doaenterprise.dev.wyo.gov/rest/api/TimeSheets/63/2019-08-30 63 is the ID of the TimeSheets/ByUserIdAndDate
// => GET http://doaenterprise.dev.wyo.gov/rest/api/TimeSheetEntries/ByTimeSheetID/63
// if it returns null the save goes to
// POST http://doaenterprise.dev.wyo.gov/rest/api/Values (sends date and timesheet)
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>
